const navara = {
  id: '4296c8a6-864b-422b-bd08-f89672dc24be',
  name: 'Navara',
  model_settings: [
    {
      color: [0, 2, 3, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 2, 3, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 2, 3, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 2, 3, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 1, 2, 3, 6, 7],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 2, 3, 5, 6, 7],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 2, 3, 5, 6, 7],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 7],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: '4x2 | SE MT' },
          { id: 1, name: '4x2 | XE MT' },
          { id: 2, name: '4x4 | SE MT' },
          { id: 3, name: '4x4 | XE MT' },
          { id: 4, name: '4x4 | XE AT' },
          { id: 5, name: '4x4 | PLAT MT' },
          { id: 6, name: '4x4 | PLAT AT' },
          { id: 7, name: '4x4 | Pro4X' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Gris Metálico',
            models: [0, 1, 2, 3, 4, 5, 6, 7],
          },
          {
            id: 1,
            type: 'color',
            name: 'Cobre',
            models: [4, 7],
          },
          {
            id: 2,
            type: 'color',
            name: 'Blanco',
            models: [0, 1, 2, 3, 4, 5, 6, 7],
          },
          {
            id: 3,
            type: 'color',
            name: 'Plata',
            models: [0, 1, 2, 3, 4, 5, 6, 7],
          },
          {
            id: 4,
            type: 'color',
            name: 'Gris Volcanico',
            models: [7],
          },
          {
            id: 5,
            type: 'color',
            name: 'Negro',
            models: [5, 6, 7],
          },
          {
            id: 6,
            type: 'color',
            name: 'Rojo',
            models: [0, 1, 2, 3, 4, 5, 6],
          },
          {
            id: 7,
            type: 'color',
            name: 'Azul',
            models: [4, 5, 6, 7],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Cabina delantera', exterior: false },
    { id: 5, name: 'Cabina trasera', exterior: false },
    { id: 6, name: 'Caja de cambios', exterior: false },
    { id: 7, name: 'Panel central - USB', exterior: false },
    { id: 8, name: 'Climatizador', exterior: false },
    { id: 9, name: 'Radio', exterior: false },
    { id: 10, name: 'Pick Up', exterior: true },
  ],
  defaultModel: 7,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-NuevaNavara.html',
}

const leaf = {
  id: '4296c8a6-824b-422b-bd08-f89672dc24be',
  name: 'Leaf',
  model_settings: [
    {
      color: [0, 1, 2],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [{ id: 0, name: 'Leaf' }],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Gris Bitono',
            models: [0],
          },
          {
            id: 1,
            type: 'color',
            name: 'Blanco Bitono',
            models: [0],
          },
          {
            id: 2,
            type: 'color',
            name: 'Rojo Bitono',
            models: [0],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Cabina delantera', exterior: false },
    { id: 5, name: 'Cabina trasera', exterior: false },
    { id: 6, name: 'Panel central', exterior: false },
    { id: 7, name: 'Radio', exterior: false },
    { id: 8, name: 'Panel central - USB', exterior: false },
    // {id: 9, name: "Exterior - Frontal", exterior: true},
    // {id: 10, name: "Exterior - Cola", exterior: true},
    // {id: 11, name: "Exterior - Izquierda", exterior: true},
    // {id: 12, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 0,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Leaf',
}

const murano = {
  id: '4296c8a6-884b-422b-bd08-f89672dc24be',
  name: 'Murano',
  model_settings: [
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [{ id: 0, name: 'EXCLUSIVE CVT AWD' }],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Negro Magnético',
            models: [0],
          },
          {
            id: 1,
            type: 'color',
            name: 'Gris Metálico',
            models: [0],
          },
          {
            id: 2,
            type: 'color',
            name: 'Café Java',
            models: [0],
          },
          {
            id: 3,
            type: 'color',
            name: 'Rojo Metálico',
            models: [0],
          },
          {
            id: 4,
            type: 'color',
            name: 'Plata',
            models: [0],
          },
          {
            id: 5,
            type: 'color',
            name: 'Blanco Perla',
            models: [0],
          },
          {
            id: 6,
            type: 'color',
            name: 'Azul Ártico',
            models: [0],
          },
          {
            id: 7,
            type: 'color',
            name: 'Naranja Atardecer',
            models: [0],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Sunroof', exterior: true },
    { id: 4, name: 'Volante', exterior: false },
    { id: 5, name: 'Radio', exterior: false },
    { id: 6, name: 'Transmisión', exterior: false },
    { id: 7, name: 'Primera fila', exterior: false },
    { id: 8, name: 'Segunda fila', exterior: false },
    { id: 9, name: 'Maletero', exterior: false },
    // {id: 10, name: "Exterior - Frontal", exterior: true},
    // {id: 11, name: "Exterior - Cola", exterior: true},
    // {id: 12, name: "Exterior - Izquierda", exterior: true},
    // {id: 13, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 0,
  defaultColor: 4,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-NuevaMurano.html',
}

const qashqai = {
  id: '4296c8a6-934b-422b-bd08-f89672dc24be',
  name: 'Qashqai',
  model_settings: [
    {
      color: [0, 1, 2, 3, 4, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [0, 1, 2, 3, 4, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [0, 1, 2, 3, 4, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [0, 1, 2, 3, 4, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [6, 7, 8, 9],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'SENSE MT' },
          { id: 1, name: 'ADVANCE MT' },
          { id: 2, name: 'ADVANCE CVT' },
          { id: 3, name: 'ADVANCE CVT PLUS' },
          { id: 4, name: 'EXCLUSIVE CVT AWD' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Blanco Perlado',
            models: [0, 1, 2, 3],
          },
          {
            id: 1,
            type: 'color',
            name: 'Gris Metálico',
            models: [0, 1, 2, 3],
          },
          {
            id: 2,
            type: 'color',
            name: 'Gris Plateado',
            models: [0, 1, 2, 3],
          },
          {
            id: 3,
            type: 'color',
            name: 'Negro',
            models: [0, 1, 2, 3],
          },
          {
            id: 4,
            type: 'color',
            name: 'Rojo Metálico',
            models: [0, 1, 2, 3],
          },
          {
            id: 5,
            type: 'color',
            name: 'Azul Tinta',
            models: [0, 1, 2, 3],
          },
          {
            id: 6,
            type: 'color',
            name: 'Azul Perlado Bi-tono',
            models: [4],
          },
          {
            id: 7,
            type: 'color',
            name: 'Gris Perlado Bi-tono',
            models: [4],
          },
          {
            id: 8,
            type: 'color',
            name: 'Blanco Perlado Bi-tono',
            models: [4],
          },
          {
            id: 9,
            type: 'color',
            name: 'Rojo Perlado Bi-tono',
            models: [4],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Sunroof', exterior: true },
    { id: 4, name: 'Volante', exterior: false },
    { id: 5, name: 'Radio', exterior: false },
    { id: 6, name: 'Transmisión', exterior: false },
    { id: 7, name: 'Primera fila', exterior: false },
    { id: 8, name: 'Segunda fila', exterior: false },
    { id: 9, name: 'Maletero', exterior: false },
  ],
  defaultModel: 4,
  defaultColor: 6,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Qashqai.html',
}

const sentra = {
  id: '4296c8a6-844b-422b-bd08-f89672dc24be',
  name: 'Sentra',
  model_settings: [
    {
      color: [3, 4, 5, 6, 7, 8, 9],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'SENSE MT' },
          { id: 1, name: 'ADVANCE MT' },
          { id: 2, name: 'ADVANCE CVT' },
          { id: 3, name: 'EXCLUSIVE CVT' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Naranjo Bitono',
            models: [1, 2, 3],
          },
          {
            id: 1,
            type: 'color',
            name: 'Gris Bitono',
            models: [1, 2, 3],
          },
          {
            id: 2,
            type: 'color',
            name: 'Blanco Bitono',
            models: [1, 2, 3],
          },
          {
            id: 3,
            type: 'color',
            name: 'Blanco Perla',
            models: [0, 1, 2, 3],
          },
          {
            id: 4,
            type: 'color',
            name: 'Rojo Escarlata',
            models: [0, 1, 2, 3],
          },
          {
            id: 5,
            type: 'color',
            name: 'Azul',
            models: [0, 1, 2, 3],
          },
          {
            id: 6,
            type: 'color',
            name: 'Naranjo',
            models: [0, 1, 2, 3],
          },
          {
            id: 7,
            type: 'color',
            name: 'Gris',
            models: [0, 1, 2, 3],
          },
          {
            id: 8,
            type: 'color',
            name: 'Plata',
            models: [0, 1, 2, 3],
          },
          {
            id: 9,
            type: 'color',
            name: 'Blanco',
            models: [0, 1, 2],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Transmisión', exterior: false },
    { id: 5, name: 'Cabina delantera', exterior: false },
    { id: 6, name: 'Cabina trasera', exterior: false },
    { id: 7, name: 'Radio', exterior: false },
    { id: 8, name: 'Panel central - USB', exterior: false },
    { id: 9, name: 'Focos delanteros', exterior: true },
    { id: 10, name: 'Sunroof', exterior: true },
    { id: 11, name: 'Aire acondicionado', exterior: false },
    // {id: 12, name: "Exterior - Frontal", exterior: true},
    // {id: 13, name: "Exterior - Cola", exterior: true},
    // {id: 14, name: "Exterior - Izquierda", exterior: true},
    // {id: 15, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 3,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-NuevoSentra',
}

const versa = {
  id: '4296c8a6-834b-422b-bd08-f89672dc24be',
  name: 'Versa',
  model_settings: [
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'SENSE MT' },
          { id: 1, name: 'SENSE CVT' },
          { id: 2, name: 'ADVANCE MT' },
          { id: 3, name: 'ADVANCE CVT' },
          { id: 4, name: 'EXCLUSIVE CVT' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Blanco Perlado',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 1,
            type: 'color',
            name: 'Plata',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 2,
            type: 'color',
            name: 'Azul Perlado Metálico',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 3,
            type: 'color',
            name: 'Negro',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 4,
            type: 'color',
            name: 'Azul Oscuro Perla',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 5,
            type: 'color',
            name: 'Naranja Metálico',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 6,
            type: 'color',
            name: 'Gris Ethos',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 7,
            type: 'color',
            name: 'Rojo Metálico',
            models: [0, 1, 2, 3, 4],
          },
          {
            id: 8,
            type: 'color',
            name: 'Gris Metálico',
            models: [0, 1, 2, 3, 4],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Transmisión', exterior: false },
    { id: 5, name: 'Cabina delantera', exterior: false },
    { id: 6, name: 'Cabina trasera', exterior: false },
    { id: 7, name: 'Radio', exterior: false },
    { id: 8, name: 'Panel central - USB', exterior: false },
    // {id: 9, name: "Exterior - Frontal", exterior: true},
    // {id: 10, name: "Exterior - Cola", exterior: true},
    // {id: 11, name: "Exterior - Izquierda", exterior: true},
    // {id: 12, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 4,
  defaultColor: 6,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Versa.html',
}

const xtrail = {
  id: '4296c8a6-874b-422b-bd08-f89672dc24be',
  name: 'Xtrail',
  model_settings: [
    {
      color: [0, 1, 2, 3, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [0, 1, 2, 3, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [0, 1, 2, 3, 5],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [6, 7, 8, 9],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [5, 6, 7, 8, 9, 10],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      color: [5, 6, 7, 8, 9, 10],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'SENSE 2ROW' },
          { id: 1, name: 'SENSE 3ROW' },
          { id: 2, name: 'ADVANCE 3ROW' },
          { id: 3, name: 'EXCLUSIVE 3ROW 4WD' },
          { id: 4, name: 'ADVANCE E-POWER' },
          { id: 5, name: 'EXCLUSIVE E-POWER' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Rojo Metálico',
            models: [0, 1, 2],
          },
          {
            id: 1,
            type: 'color',
            name: 'Blanco Perlado',
            models: [0, 1, 2],
          },
          {
            id: 2,
            type: 'color',
            name: 'Plata',
            models: [0, 1, 2],
          },
          {
            id: 3,
            type: 'color',
            name: 'Azul',
            models: [0, 1, 2],
          },
          {
            id: 4,
            type: 'color',
            name: 'Gris Metálico',
            models: [0, 1, 2],
          },
          {
            id: 5,
            type: 'color',
            name: 'Negro',
            models: [0, 1, 2, 4, 5],
          },
          {
            id: 6,
            type: 'color',
            name: 'Azul Bi-Tono',
            models: [3, 4, 5],
          },
          {
            id: 7,
            type: 'color',
            name: 'Plata Champagne Bi-Tono',
            models: [3, 4, 5],
          },
          {
            id: 8,
            type: 'color',
            name: 'Gris Volcanico Bi-Tono',
            models: [3, 4, 5],
          },
          {
            id: 9,
            type: 'color',
            name: 'Blanco Perlado Bi-Tono',
            models: [3, 4, 5],
          },
          {
            id: 10,
            type: 'color',
            name: 'Rojo Tina',
            models: [4, 5],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Sunroof', exterior: true },
    { id: 4, name: 'Volante', exterior: false },
    { id: 5, name: 'Radio', exterior: false },
    { id: 6, name: 'Transmisión', exterior: false },
    { id: 7, name: 'Primera fila', exterior: false },
    { id: 8, name: 'Segunda fila', exterior: false },
    { id: 9, name: 'Tercera fila', exterior: false },
  ],
  defaultModel: 3,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Xtrail.html',
}

const escolar = {
  id: '4296c8a6-894b-422b-bd08-f89672dc24be',
  name: 'NV350-Escolar',
  model_settings: [
    {
      color: [0],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [{ id: 0, name: 'ESCOLAR' }],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Amarillo',
            models: [0],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Radio', exterior: false },
    { id: 5, name: 'Transmisión', exterior: false },
    { id: 6, name: 'Primera fila', exterior: false },
    { id: 7, name: 'Pasajeros - Frontal', exterior: false },
    { id: 8, name: 'Pasajeros - Cola', exterior: false },
    // {id: 9, name: "Exterior - Frontal", exterior: true},
    // {id: 10, name: "Exterior - Cola", exterior: true},
    // {id: 11, name: "Exterior - Izquierda", exterior: true},
    // {id: 12, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 0,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-NV350Escolar.html',
}

const urvan = {
  id: '4296c8a6-904b-422b-bd08-f89672dc24be',
  name: 'NV350-Urvan',
  model_settings: [
    {
      color: [0],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    {
      color: [0],
      cameras: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'Bus Wide S-Long' },
          { id: 1, name: 'Cargo Wide S-Long' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Plata',
            models: [0, 1],
          },
          {
            id: 1,
            type: 'color',
            name: 'Blanco',
            models: [0, 1],
          },
          {
            id: 2,
            type: 'color',
            name: 'Gris',
            models: [0, 1],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Radio', exterior: false },
    { id: 5, name: 'Transmisión', exterior: false },
    { id: 6, name: 'Primera fila', exterior: false },
    { id: 7, name: 'Cabina Trasera - Frontal', exterior: false },
    { id: 8, name: 'Cabina Trasera - Cola', exterior: false },
    // {id: 9, name: "Exterior - Frontal", exterior: true},
    // {id: 10, name: "Exterior - Cola", exterior: true},
    // {id: 11, name: "Exterior - Izquierda", exterior: true},
    // {id: 12, name: "Exterior - Derecha", exterior: true},
  ],
  defaultModel: 0,
  defaultColor: 0,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Urvan.html',
}

const kicks = {
  id: '4296c8a6-854b-422b-bd08-f89672dc24be',
  name: 'Kicks',
  model_settings: [
    {
      color: [0, 1, 2, 3, 4, 5, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 9],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 9],
    },
    {
      color: [0, 1, 2, 3, 4, 5, 6],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 9],
    },
    {
      color: [7, 8, 9, 10],
      cameras: [0, 1, 2, 3, 4, 5, 6, 7, 9],
    },
  ],
  settings: {
    menus: [
      {
        id: 0,
        name: 'Modelos',
        type: 'label',
        path: 'models',
        subMenus: [
          {
            id: 0,
            name: 'Próximamente',
          },
        ],
      },
      {
        id: 1,
        name: 'Versiones',
        type: 'label',
        path: 'versions',
        subMenus: [
          { id: 0, name: 'SENSE MT' },
          { id: 1, name: 'ADVANCE MT' },
          { id: 2, name: 'ADVANCE CVT' },
          { id: 3, name: 'EXCLUSIVE CVT' },
        ],
      },
      {
        id: 2,
        name: 'Colores',
        type: 'image',
        path: 'ext-colors',
        subMenus: [
          {
            id: 0,
            type: 'color',
            name: 'Blanco Perlado',
            models: [0, 1, 2],
          },
          {
            id: 1,
            type: 'color',
            name: 'Plata Metálico',
            models: [0, 1, 2, 3],
          },
          {
            id: 2,
            type: 'color',
            name: 'Gris Metálico',
            models: [0, 1, 2, 3],
          },
          {
            id: 3,
            type: 'color',
            name: 'Gris Volcanico',
            models: [0, 1, 2],
          },
          {
            id: 4,
            type: 'color',
            name: 'Azul Metálico',
            models: [0, 1, 2],
          },
          {
            id: 5,
            type: 'color',
            name: 'Rojo Metálico',
            models: [0, 1, 2],
          },
          {
            id: 6,
            type: 'color',
            name: 'Negro',
            models: [0, 1, 2],
          },
          {
            id: 7,
            type: 'color',
            name: 'Blanco Perlado Bi-Tono',
            models: [3],
          },
          {
            id: 8,
            type: 'color',
            name: 'Rojo Metálico Bi-Tono',
            models: [3],
          },
          {
            id: 9,
            type: 'color',
            name: 'Gris Volcanico Bi-Tono',
            models: [3],
          },
          {
            id: 10,
            type: 'color',
            name: 'Azul Metálico Bi-Tono',
            models: [3],
          },
        ],
      },
    ],
  },
  cameras: [
    { id: 0, name: 'Exterior', exterior: true },
    { id: 1, name: 'Rueda delantera', exterior: true },
    { id: 2, name: 'Rueda trasera', exterior: true },
    { id: 3, name: 'Volante', exterior: false },
    { id: 4, name: 'Cabina delantera', exterior: false },
    { id: 5, name: 'Cabina trasera', exterior: false },
    { id: 6, name: 'Panel central', exterior: false },
    { id: 7, name: 'Radio', exterior: false },
    { id: 9, name: 'Cabecera conductor', exterior: false },
  ],
  defaultModel: 3,
  defaultColor: 4,
  cotizacionURL: 'nissan.cl/forms/Cotizacion-Kicks',
}

export default {
  navara,
  leaf,
  murano,
  sentra,
  versa,
  xtrail,
  qashqai,
  'nv350-escolar': escolar,
  'nv350-urvan': urvan,
  kicks,
}

import React from 'react';
// Styles
import './Logo.css';

const Logo = ({ url, alt, inverted, ...rest }) => {
  let brandLogo = url ? url : `${process.env.PUBLIC_URL}/logo.svg`;

  return <img src={brandLogo} alt={alt} className={`logo ${inverted ? 'inverted' : ''}`} {...rest} />;
};

export default Logo;

import React from "react";
import PixelStreamingContext from "./Context";

import "webrtc-adapter";

const MessageType = {
  /**********************************************************************/

  /*
   * Control Messages. Range = 0..49.
   */
  IFrameRequest: 0,
  RequestQualityControl: 1,
  MaxFpsRequest: 2,
  AverageBitrateRequest: 3,
  StartStreaming: 4,
  StopStreaming: 5,

  /**********************************************************************/

  /*
   * Input Messages. Range = 50..89.
   */

  // Generic Input Messages. Range = 50..59.
  UIInteraction: 50,
  Command: 51,

  // Keyboard Input Message. Range = 60..69.
  KeyDown: 60,
  KeyUp: 61,
  KeyPress: 62,

  // Mouse Input Messages. Range = 70..79.
  MouseEnter: 70,
  MouseLeave: 71,
  MouseDown: 72,
  MouseUp: 73,
  MouseMove: 74,
  MouseWheel: 75,

  // Touch Input Messages. Range = 80..89.
  TouchStart: 80,
  TouchEnd: 81,
  TouchMove: 82,

  /**************************************************************************/
};

// connect to signalling and create the RTC connection
class PixelStreaming extends React.Component {
  constructor(props) {
    super(props);

    this.ws_config = this.props.config;
    this.state = {
      connected: false,
      setVideoRef: this.setVideoRef,
      startStream: this.startStream,
      send: this.send,
      emitDescriptor: this.emitDescriptor,
    };

    this.ws = null;
    this.peer = null;
    this.videoRef = React.createRef();
    this.videoStreamRef = React.createRef();
    this.dchannel = null;
    this.is_reconnection = false;
    this.cfg = null;
  }

  send = (data) => {
    if (this.dchannel && this.dchannel.readyState == "open") {
      //console.log('Sending data on dataconnection', self.dcClient)
      this.dchannel.send(data);
    }
  };

  // A generic message has a type and a descriptor.
  emitDescriptor = (messageType, descriptor) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("Sended descriptor: ", descriptor);
      return;
    }

    // Convert the dscriptor object into a JSON string.
    let descriptorAsString = JSON.stringify(descriptor);
    console.log("descritpr", descriptor);

    // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
    // a time.
    let data = new DataView(
      new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length)
    );
    let byteIdx = 0;
    data.setUint8(byteIdx, messageType);
    byteIdx++;
    data.setUint16(byteIdx, descriptorAsString.length, true);
    byteIdx += 2;
    for (let i = 0; i < descriptorAsString.length; i++) {
      data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true);
      byteIdx += 2;
    }
    this.send(data.buffer);
  };

  componentDidMount() {
    // create socket
    this.ws = this.connectSignalling();
  }

  setVideoRef = (video) => {
    console.log("added video", video);
    this.videoRef = video;
  };

  connectSignalling = () => {
    if (!window.WebSocket && !window.MozWebSocket)
      throw new Error("Your browser doesn't support WebSocket");

    let url = `${this.ws_config.url}?streamerKey=${this.ws_config.streamerKey}&uuid=${this.ws_config.uuid}&reservationKey=${this.ws_config.reservationKey}`;

    const ws = window.WebSocket
      ? new window.WebSocket(url)
      : new window.MozWebSocket(url);

    ws.onopen = this.onSignallingOpen;
    ws.onmessage = this.onSignallingMessage;
    ws.onerror = this.onSignallingError;
    ws.onclose = this.onSignallingClose;

    return ws;
  };

  onSignallingMessage = (e) => {
    var msg = JSON.parse(e.data);
    console.log("%c[Inbound SS]", "background: violet; color: black", msg);
    if (msg.type === "config") {
      console.log(
        "%c[Inbound SS (config)]",
        "background: lightblue; color: black",
        msg
      );
      let parOptions = { peerConnectionOptions: msg.peerConnectionOptions };
      this.cfg = parOptions.peerConnectionOptions || {};
    } else if (msg.type === "playerCount") {
      console.log(
        "%c[Inbound SS (playerCount)]",
        "background: lightblue; color: black",
        msg
      );
    } else if (msg.type === "offer") {
      console.log(
        "%c[Inbound SS (offer)]",
        "background: lightblue; color: black",
        msg
      );
      this.receiveOffer(msg);
    } else if (msg.type === "answer") {
      console.log(
        "%c[Inbound SS (answer)]",
        "background: lightblue; color: black",
        msg
      );
      this.receiveAnswer(msg);
      // };
    } else if (msg.type === "iceCandidate") {
      // onWebRtcIce(msg.candidate);
      this.handleCandidateFromServer(msg.candidate);
    } else {
      // console.log(`Invalid SS message type: ${msg.type}`);
    }
  };

  receiveAnswer = (answer) => {
    var answerDesc = new RTCSessionDescription(answer);
    this.peer.setRemoteDescription(answerDesc);

    let receivers = this.peer.getReceivers();
    for (let receiver of receivers) {
      receiver.playoutDelayHint = 0;
    }
  };

  setupPeerConnection = (pc) => {
    //Setup peerConnection events

    pc.onconnectionstatechange = (_) => {
      if (this.peer.connectionState === "connected") {
        this.setState({ connected: true });
        this.props.onConnected();
      } else this.setState({ connected: false });
    };

    pc.onsignalingstatechange = this.log;
    pc.oniceconnectionstatechange = this.log;
    pc.onicegatheringstatechange = this.log;

    pc.ontrack = this.handleOnTrack;
    pc.onicecandidate = this.onicecandidate;
    pc.ondatachannel = this.onDataChannel;
  };

  setupTransceiversAsync = async (pc) => {
    // Setup a transceiver for getting UE video
    pc.addTransceiver("video", { direction: "recvonly" });
    pc.addTransceiver("audio", { direction: "recvonly" });
  };

  mungeSDPOffer = (offer) => {
    let audioSDP = "";

    // set max bitrate to highest bitrate Opus supports
    audioSDP += "maxaveragebitrate=510000;";

    // Force mono or stereo based on whether ?forceMono was passed or not
    audioSDP += "sprop-stereo=1;stereo=1;";

    // enable in-band forward error correction for opus audio
    audioSDP += "useinbandfec=1";

    // We use the line 'useinbandfec=1' (which Opus uses) to set our Opus specific audio parameters.
    offer.sdp = offer.sdp.replace("useinbandfec=1", audioSDP);
  };

  receiveOffer = (offer) => {
    var offerDesc = new RTCSessionDescription(offer);

    if (!this.peer) {
      console.log("Creating a new PeerConnection in the browser.", this.cfg);
      this.cfg.sdpSemantics = "unified-plan";
      this.cfg.offerExtmapAllowMixed = false;
      this.cfg.bundlePolicy = "balanced";

      this.peer = new RTCPeerConnection(this.cfg);
      this.setupPeerConnection(this.peer);

      // Put things here that happen post transceiver setup
      this.peer.setRemoteDescription(offerDesc).then(() => {
        this.setupTransceiversAsync(this.peer).finally(() => {
          this.peer
            .createAnswer()
            .then((answer) => {
              this.mungeSDPOffer(answer);
              return this.peer.setLocalDescription(answer);
            })
            .then(() => {
              const ws = this.ws;
              if (ws && ws.readyState === WebSocket.OPEN) {
                let answerStr = JSON.stringify(
                  this.peer.currentLocalDescription
                );
                console.log(
                  "%c[Outbound SS message (answer)]",
                  "background: lightgreen; color: black",
                  this.peer.currentLocalDescription
                );
                ws.send(answerStr);
              }
            })
            .then(() => {
              let receivers = this.peer.getReceivers();
              for (let receiver of receivers) {
                receiver.playoutDelayHint = 0;
              }
            })
            .catch((error) => console.error("createAnswer() failed:", error));
        });
      });
    }
  };

  onSignallingClose = (e) => {
    console.log(
      `Signalling WS closed: ${JSON.stringify(e.code)} - ${e.reason}`
    );
    this.ws = null;
    this.is_reconnection = true;
    this.ws = this.connectSignalling();
  };

  onSignallingError = (e) => {
    console.log(`Signalling WS error: ${JSON.stringify(e)}`);
  };

  onSignallingOpen = (e) => {
    console.log("Websocket connected");
  };

  createPeer(msg) {
    this.log("creating peer!");
    let parOptions = { peerConnectionOptions: msg.peerConnectionOptions };
    let cfg = parOptions.peerConnectionOptions || {};
    cfg.sdpSemantics = "unified-plan";
    cfg.offerExtmapAllowMixed = false;
    cfg.bundlePolicy = "balanced";

    const peer = new RTCPeerConnection(cfg);

    if (peer.SetBitrate)
      console.log("Hurray! there's RTCPeerConnection.SetBitrate function");

    peer.onconnectionstatechange = (_) => {
      if (this.peer.connectionState === "connected") {
        this.setState({ connected: true });
        this.props.onConnected();
      } else this.setState({ connected: false });
    };

    peer.onsignalingstatechange = this.log;
    peer.oniceconnectionstatechange = this.log;
    peer.onicegatheringstatechange = this.log;

    peer.ontrack = this.handleOnTrack;
    peer.onicecandidate = this.onicecandidate;
    peer.onnegotiationneeded = () => {
      console.log("need degotiation");
    };

    return peer;
  }

  handleOnTrack = (e) => {
    if (e.track) {
      console.log(
        "Got track. | Kind=" +
          e.track.kind +
          " | Id=" +
          e.track.id +
          " | readyState=" +
          e.track.readyState +
          " |"
      );
    }
    if (e.track.kind == "video") {
      this.videoStreamRef = e.streams[0];

      // All tracks are added "muted" by WebRTC/browser and become unmuted when media is being sent
      e.track.onunmute = () => {
        this.videoStreamRef = e.streams[0];
      };
    }
  };

  log = (e) => {
    // console.log('peer: ', e);
  };

  onicecandidate = (e) => {
    let candidate = e.candidate;
    if (candidate && candidate.candidate) {
      console.log(
        "%c[Browser ICE candidate]",
        "background: violet; color: black",
        "| Type=",
        candidate.type,
        "| Protocol=",
        candidate.protocol,
        "| Address=",
        candidate.address,
        "| Port=",
        candidate.port,
        "|"
      );
      const ws = this.ws;
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "iceCandidate", candidate: candidate }));
      }
    }
  };

  onDataChannel = (dataChannelEvent) => {
    // This is the primary data channel code path when we are "receiving"
    console.log(
      "Data channel created for us by browser as we are a receiving peer."
    );
    this.dchannel = dataChannelEvent.channel;
    this.setupDataChannelCallbacks(this.dchannel);
  };

  setupDataChannelCallbacks = (datachannel) => {
    try {
      // Inform browser we would like binary data as an ArrayBuffer (FF chooses Blob by default!)
      datachannel.binaryType = "arraybuffer";

      datachannel.onopen = (e) => {
        console.log("Data channel connected");
        this.send(new Uint8Array([MessageType.RequestQualityControl]).buffer);
      };

      datachannel.onclose = (e) => {
        console.log("Data channel connected", e);
      };

      datachannel.onerror = (e) => {
        console.error("Data channel error", e);
      };

      return datachannel;
    } catch (e) {
      console.warn("No data channel", e);
      return null;
    }
  };

  handleCreateOffer = () => {
    this.peer
      .createOffer({
        offerToReceiveAudio: -1,
        offerToReceiveVideo: 1,
      })
      .then((offer) => {
        this.mungeSDPOffer(offer);

        this.peer.setLocalDescription(offer);

        const ws = this.ws;
        if (ws && ws.readyState === WebSocket.OPEN) {
          let offerStr = JSON.stringify(offer);
          console.log(`-> SS: offer:\n${offerStr}`);
          ws.send(offerStr);
        }
      });
  };

  handleCandidateFromServer = (iceCandidate) => {
    let candidate = new RTCIceCandidate(iceCandidate);
    console.log(
      "%c[Unreal ICE candidate]",
      "background: pink; color: black",
      "| Type=",
      candidate.type,
      "| Protocol=",
      candidate.protocol,
      "| Address=",
      candidate.address,
      "| Port=",
      candidate.port,
      "|"
    );

    this.peer.addIceCandidate(candidate).catch((e) => {
      console.error("Failed to add ICE candidate", e);
    });
  };

  startStream = () => {
    let videoTrack, audioTrack;
    this.peer.getReceivers().forEach((receiver) => {
      if (receiver.track.kind === "audio" && !audioTrack) {
        audioTrack = receiver.track;
      }

      if (receiver.track.kind === "video" && !videoTrack) {
        videoTrack = receiver.track;
      }
    });

    let stream = new MediaStream();

    if (audioTrack) {
      stream.addTrack(audioTrack);
    }

    if (videoTrack) {
      stream.addTrack(videoTrack);
    }

    this.videoRef.srcObject = stream;
    console.log(stream.getTracks()); // check console logs
  };

  render() {
    return (
      <PixelStreamingContext.Provider value={this.state}>
        {this.props.children}
      </PixelStreamingContext.Provider>
    );
  }
}

export default PixelStreaming;

import React from 'react'

import Preview from '../Preview'
import MediaQuery from 'react-responsive'

import PixelStreamingContext from '../PixelStreaming/Context'

import DefaultData from '../../data'
import { CgChevronRight, CgChevronLeft } from 'react-icons/cg'
import { GoPlus, GoDash } from 'react-icons/go'

const ACTIVE_MODEL = DefaultData[process.env.REACT_APP_ACTIVE_MODEL]

class Mobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // VerticalNavbar States
      exterior: true,
      filteredCameras: [],
      menuActions: [],
      selectedLeftMenuId: 2,
      selectedCamera: 0,
      isCameraMenuOpen: false,

      // RightTopFrame States
      selectedMenuId: 1,
      menuOptions: ACTIVE_MODEL.settings.menus[1].subMenus,
    }
  }

  componentDidMount = () => {
    this.handleSetCameras(this.state.exterior, this.setMenuActions)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.currentVariant !== this.props.currentVariant) {
      this.handleSetCameras(this.state.exterior)
    }
  }

  handleSetSelectedLeftMenuId = id => {
    this.setState({ selectedLeftMenuId: id })
  }

  handleIsCameraMenuOpen = isOpen => {
    this.setState({ isCameraMenuOpen: isOpen })
  }

  handleSetSelectedCamera = id => {
    this.setState({ selectedCamera: id })
  }

  handleSetExterior = ext => {
    this.handleSetCameras(ext)
    this.setState({ exterior: ext })
  }

  handleSetCameras = (isExt, callback) => {
    let possibleCameras = ACTIVE_MODEL.model_settings[this.props.currentVariant].cameras
    let camerasToShow = ACTIVE_MODEL.cameras.filter(camera => camera.exterior === isExt && possibleCameras.some(id => id === camera.id))

    this.setState({ filteredCameras: camerasToShow }, callback)
  }

  setMenuActions = () => {
    const volante = ACTIVE_MODEL.cameras.find(camera => camera.name === 'Volante')
    const exterior = ACTIVE_MODEL.cameras.find(camera => camera.name === 'Exterior')

    this.setState({
      menuActions: [
        {
          name: 'Interior',
          camera: volante,
        },
        {
          name: 'Exterior',
          camera: exterior,
        },
      ],
    })
  }

  handleChangeSelectedMenu = id => {
    let menuOptions = ACTIVE_MODEL.settings.menus[id].subMenus
    if (id === 2) {
      menuOptions = this.filterColors()
    }

    this.setState({
      selectedMenuId: id,
      menuOptions: menuOptions,
    })
  }

  filterColors = () => {
    const possibleColors = ACTIVE_MODEL.model_settings[this.props.currentVariant].color
    const allColors = ACTIVE_MODEL.settings.menus[2].subMenus
    const colorsToShow = allColors.filter(color => possibleColors.some(id => id === color.id))

    return colorsToShow
  }

  render() {
    const TopFrame = () => {
      return (
        <div className='flex flex-row flex-1 h-3/4 '>
          <VerticalNavbar
            setExterior={this.handleSetExterior}
            menuActions={this.state.menuActions}
            handleChangeCamera={this.props.handleChangeCamera}
            selectedLeftMenuId={this.state.selectedLeftMenuId}
            handleSetSelectedLeftMenuId={this.handleSetSelectedLeftMenuId}
            handleSetSelectedCamera={this.handleSetSelectedCamera}
          />
          <RightTopFrame
            isExterior={this.state.exterior}
            selectedMenuId={this.state.selectedMenuId}
            handleChangeMenu={this.handleChangeSelectedMenu}
            selectedCamera={this.state.selectedCamera}
            handleSetSelectedCamera={this.handleSetSelectedCamera}
            cameras={this.state.filteredCameras}
            handleChangeCamera={this.props.handleChangeCamera}
            isCameraMenuOpen={this.state.isCameraMenuOpen}
            handleIsCameraMenuOpen={this.handleIsCameraMenuOpen}
            handleZoomIn={this.props.handleZoomIn}
            handleZoomOut={this.props.handleZoomOut}
          />
        </div>
      )
    }
    const BottomMenu = () => {
      return (
        <div className='flex flex-row h-1/4'>
          <Selector
            currentVariant={this.props.currentVariant}
            handleChangeVariant={this.props.handleChangeVariant}
            currentColor={this.props.currentColor}
            handleChangeColor={this.props.handleChangeColor}
            selectedMenuId={this.state.selectedMenuId}
            menuOptions={this.state.menuOptions}
          />
          <QuoteButton />
        </div>
      )
    }

    return (
      <div className='flex flex-col h-full'>
        <TopFrame />
        <BottomMenu />
      </div>
    )
  }
}

class VerticalNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.labelButtons = [
      // {id: 0, label: "Cinematica"},
      { id: 1, label: 'Interior', exterior: false },
      { id: 2, label: 'Exterior', exterior: true },
    ]
  }

  handleOnClickExterior = button => {
    this.props.handleSetSelectedLeftMenuId(button.id)
    if ('exterior' in button) {
      // TODO: modify for cinematics button
      let action = this.props.menuActions.find(action => action.name === button.label)
      this.props.handleSetSelectedCamera(action.camera.id)
      this.props.handleChangeCamera(action.camera.id)
      this.props.setExterior(button.exterior)
    }
  }

  render() {
    const MenuItem = props => {
      const selected = props.selected ? 'border-nissanRed-default border-r-2' : ''
      //TODO: modify button height for rotated names ,,, use grid?
      return (
        <button className={`flex ${selected}`}>
          <p className='inline-block w-full py-8 font-normal -rotate-90 text-gray-70 whitespace-nowrap' onClick={props.onClick}>
            {props.label}
          </p>
        </button>
      )
    }
    return (
      <div className='z-20 flex flex-col-reverse gap-6 py-16 overflow-hidden text-sm bg-white w-14 md:text-base'>
        {this.labelButtons.map(btn => {
          return <MenuItem id={btn.id} key={btn.label} label={btn.label} onClick={() => this.handleOnClickExterior(btn)} selected={this.props.selectedLeftMenuId === btn.id} />
        })}
      </div>
    )
  }
}

class RightTopFrame extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = e => {
    console.log(e.target)
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const ButtonMenu = props => {
      const selected = props.selected ? 'border-nissanRed-default border-b-2' : ''
      return (
        <div className={`flex h-fit w-fit text-gray-600 text-base font-normal leading-6 py-2 ${selected}`} onClick={props.onClick}>
          {props.label}
        </div>
      )
    }

    return (
      <div className='flex flex-col justify-end w-full h-full text-sm md:text-base'>
        {this.state.isOpen && <div className='absolute w-full h-full z-9'></div>}
        <div className='flex flex-row h-full'>
          <div
            ref={this.wrapperRef}
            className='absolute z-10 flex flex-col mb-12 h-3/4 bg-black/30 w-fit place-content-center'
            onClick={e => {
              if (!this.state.isOpen) this.setState({ isOpen: true })
            }}>
            {this.state.isOpen && (
              <div className='flex flex-col w-32 h-full'>
                <div className='w-full py-2 text-center text-white bg-black/30'>{this.props.isExterior ? 'Exterior' : 'Interior'}</div>
                <div className='flex flex-col justify-start w-full gap-4 px-4 pb-12 mb-6 overflow-y-scroll divide-y place-content-center divide-gray-100/30 '>
                  {this.props.cameras.map(camera => {
                    const selectedColor = this.props.selectedCamera === camera.id ? 'text-white' : 'text-white/70'
                    return (
                      <button
                        key={camera.id}
                        className={`text-center px-2 pt-2 ${selectedColor}`}
                        onClick={() => {
                          this.props.handleSetSelectedCamera(camera.id)
                          this.props.handleChangeCamera(camera.id)
                        }}>
                        {camera.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            )}
            {!this.state.isOpen && (
              <button
                onClick={e => {
                  if (!this.state.isOpen) this.setState({ isOpen: true })
                  e.stopPropagation()
                }}>
                <CgChevronRight className='m-1 text-base text-white/40 hover:text-white/20' />
              </button>
            )}
          </div>
        </div>
        <div className='z-30 flex flex-row justify-center w-full gap-6 mb-6'>
          <div className={'text-white '} onClick={this.props.handleZoomIn}>
            <GoPlus className='h-6 w-6 drop-shadow-[0_0px_3px_rgba(0,0,0,1)]' />
          </div>
          <div className={'text-white '} onClick={this.props.handleZoomOut}>
            <GoDash className='h-6 w-6 drop-shadow-[0_0px_3px_rgba(0,0,0,1)]' />
          </div>
        </div>
        <div className='z-30 flex flex-row w-full h-12 gap-8 pl-5 bg-nissanGray-second'>
          {ACTIVE_MODEL.settings.menus.map(menu => (
            <ButtonMenu
              key={menu.name}
              label={menu.name}
              selected={this.props.selectedMenuId === menu.id}
              onClick={() => {
                console.log(menu)
                this.props.handleChangeMenu(menu.id)
              }}
            />
          ))}
        </div>
      </div>
    )
  }
}

class Selector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentVariantId: props.currentVariant,
      scrollHorizontal: 0,
    }
  }

  handleChangeVariant = skip => {
    const currentVariant = this.state.currentVariantId + skip
    if (currentVariant >= 0 && currentVariant < this.props.menuOptions.length) {
      this.setState({ currentVariantId: currentVariant })
      this.props.handleChangeVariant(currentVariant)
    }
  }

  render() {
    const RightArrow = () => {
      return (
        <div className='flex items-center h-full px-4' onClick={() => this.handleChangeVariant(1)}>
          <CgChevronRight className='text-white' />
        </div>
      )
    }
    const LeftArrow = () => {
      return (
        <div className='flex items-center h-full px-4' onClick={() => this.handleChangeVariant(-1)}>
          <CgChevronLeft className='text-white' />
        </div>
      )
    }

    return (
      <div className='z-20 flex flex-row w-full h-full overflow-y-hidden bg-nissanGray-default'>
        {this.props.selectedMenuId === 0 && <ModelSelector models={this.props.menuOptions} />}
        {this.props.selectedMenuId === 1 && (
          <>
            <LeftArrow />
            <InfoSelector currentVariantId={this.state.currentVariantId} variants={this.props.menuOptions} />
            <RightArrow />
          </>
        )}
        {this.props.selectedMenuId === 2 && <ColorSelector colors={this.props.menuOptions} currentColor={this.props.currentColor} handleChangeColor={this.props.handleChangeColor} />}
      </div>
    )
  }
}

class InfoSelector extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const Iterator = props => {
      return (
        <div className='flex flex-row justify-between flex-grow pb-1 border-b-4 md:border-b-4'>
          {props.variants &&
            props.variants.map((variant, idx) => {
              return (
                <p key={idx} className={`${variant.id === props.currentVariantId ? 'text-white' : 'text-gray-500'} text-lg md:text-xl font-normal`}>
                  {`0${idx + 1}.`}
                </p>
              )
            })}
        </div>
      )
    }

    const CarInfo = props => {
      return (
        <div className='flex flex-col w-full h-full gap-3 md:gap-8'>
          <p className='text-2xl font-light text-white md:text-2xl'>{props.variants[props.currentVariantId].name}</p>
          <p className='text-2xl font-semibold text-white md:text-4xl'>{ACTIVE_MODEL.name}</p>
        </div>
      )
    }

    const InfoBottom = () => {
      return (
        <div className='flex flex-row w-full'>
          <p className='text-xs text-white border-t-2'>Información</p>
        </div>
      )
    }

    return (
      <div className='flex flex-col w-full px-2 py-4 text-white'>
        <Iterator variants={this.props.variants} currentVariantId={this.props.currentVariantId} />
        <CarInfo variants={this.props.variants} currentVariantId={this.props.currentVariantId} />
        {false && <InfoBottom />}
      </div>
    )
  }
}

class ColorSelector extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const menuId = 2
    const menuPath = ACTIVE_MODEL.settings.menus[menuId].path

    return (
      <div className='w-full h-full px-6 overflow-y-auto bg-gray-200'>
        <div className='grid justify-between h-full grid-cols-4 py-4'>
          {this.props.colors.map((color, idx) => {
            const selected = this.props.currentColor === color.id ? 'border border-black' : ''
            return (
              <button key={idx} className={`w-fit mx-4 h-full overflow-clip transition duration-500 ease-in-out shrink-0 py-4`} onClick={() => this.props.handleChangeColor(color)}>
                <img className={`rounded-full ${selected}`} src={`${process.env.PUBLIC_URL}/img/${process.env.REACT_APP_ACTIVE_MODEL}/${menuPath}/${color.id}.webp`} alt={color.name} />
              </button>
            )
          })}
        </div>
      </div>
    )
  }
}

class ModelSelector extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='flex flex-col items-center w-full bg-white place-content-center'>
        {this.props.models.map((model, idx) => {
          return (
            <p
              className='text-2xl font-light text-black'
              // onClick={() => this.props.handleChangeModel(model)}
            >
              {model.name}
            </p>
          )
        })}
      </div>
    )
  }
}

class QuoteButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className='z-20 flex items-center justify-center bg-nissanRed-default'
        onClick={() => {
          window.open(`//${ACTIVE_MODEL.cotizacionURL}`)
        }}>
        <p className='font-normal text-white -rotate-90 text-md md:text-lg'>Cotizar</p>
      </div>
    )
  }
}

Mobile.contextType = PixelStreamingContext
export default Mobile

import React from 'react'
import PixelStreamingContext from './Context'

// Must be kept in sync with PixelStreamingProtocol::EToUE4Msg C++ enum.
const MessageType = {
  /**********************************************************************/

  /*
   * Control Messages. Range = 0..49.
   */
  IFrameRequest: 0,
  RequestQualityControl: 1,
  MaxFpsRequest: 2,
  AverageBitrateRequest: 3,
  StartStreaming: 4,
  StopStreaming: 5,

  /**********************************************************************/

  /*
   * Input Messages. Range = 50..89.
   */

  // Generic Input Messages. Range = 50..59.
  UIInteraction: 50,
  Command: 51,

  // Keyboard Input Message. Range = 60..69.
  KeyDown: 60,
  KeyUp: 61,
  KeyPress: 62,

  // Mouse Input Messages. Range = 70..79.
  MouseEnter: 70,
  MouseLeave: 71,
  MouseDown: 72,
  MouseUp: 73,
  MouseMove: 74,
  MouseWheel: 75,

  // Touch Input Messages. Range = 80..89.
  TouchStart: 80,
  TouchEnd: 81,
  TouchMove: 82,

  /**************************************************************************/
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const MouseButton = {
  MainButton: 0, // Left button.
  AuxiliaryButton: 1, // Wheel button.
  SecondaryButton: 2, // Right button.
  FourthButton: 3, // Browser Back button.
  FifthButton: 4, // Browser Forward button.
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
const MouseButtonsMask = {
  PrimaryButton: 1, // Left button.
  SecondaryButton: 2, // Right button.
  AuxiliaryButton: 4, // Wheel button.
  FourthButton: 8, // Browser Back button.
  FifthButton: 16, // Browser Forward button.
}

class Player extends React.Component {
  constructor(props) {
    super(props)

    this.videoRef = React.createRef()
    this.playerRef = React.createRef()

    if (process.env.REACT_APP_DEBUG_PRINTINPUTS) {
      this.print_inputs = true
    }

    this.playerElementClientRect = undefined
    this.normalizeAndQuantizeUnsigned = undefined
    this.normalizeAndQuantizeSigned = undefined

    this._orientationChangeTimeout = undefined

    this.resizeTimeout = undefined
    this.lastTimeResized = new Date().getTime()

    this.fingers = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    this.fingerIds = new Map()
  }

  setVideoRef = element => {
    this.context.setVideoRef(element)
    this.videoRef = element
  }

  play = () => {
    this.context.startStream()
  }

  setupHtmlEvents = () => {
    //Window events
    window.addEventListener('resize', this.resizePlayerStyle, true)
    window.addEventListener('orientationchange', this.onOrientationChange)
  }

  onOrientationChange = e => {
    clearTimeout(this._orientationChangeTimeout)
    this._orientationChangeTimeout = setTimeout(() => {
      this.resizePlayerStyle()
    }, 500)
  }

  resizePlayerStyle = e => {
    var playerElement = this.playerRef

    if (!playerElement) return

    // resizePlayerStyleToArbitrarySize(playerElement);

    // Calculating and normalizing positions depends on the width and height of
    // the player.
    this.playerElementClientRect = playerElement.getBoundingClientRect()
    this.updateVideoStreamSize()
    this.setupNormalizeAndQuantize()
    // resizeFreezeFrameOverlay();
  }

  componentDidMount = () => {
    this.setupHtmlEvents()
    this.resizePlayerStyle()
    this.registerInputs()
    this.registerHoveringMouseEvents()
  }

  updateVideoStreamSize = () => {
    var now = new Date().getTime()
    if (now - this.lastTimeResized > 1000) {
      var playerElement = this.playerRef

      if (!playerElement) return

      let descriptor = {
        Instruction: 'resize',
        Width: playerElement.clientWidth,
        Height: playerElement.clientHeight,
      }

      this.context.emitDescriptor(50, descriptor)
      this.lastTimeResized = new Date().getTime()
    } else {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(this.updateVideoStreamSize, 1000)
    }
  }

  updateVideoStreamSize = () => {
    var now = new Date().getTime()
    if (now - this.lastTimeResized > 1000) {
      var playerElement = this.playerRef

      if (!playerElement) return

      let descriptor = {
        Instruction: 'resize',
        Width: playerElement.clientWidth,
        Height: playerElement.clientHeight,
      }

      this.context.emitDescriptor(50, descriptor)
      this.lastTimeResized = new Date().getTime()
    } else {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(this.updateVideoStreamSize, 1000)
    }
  }

  // A hovering mouse works by the user clicking the mouse button when they want
  // the cursor to have an effect over the video. Otherwise the cursor just
  // passes over the browser.
  registerHoveringMouseEvents = () => {
    var playerElement = this.playerRef

    if (!playerElement) return

    playerElement.onmousemove = e => {
      this.emitMouseMove(e.offsetX, e.offsetY, e.movementX, e.movementY)
      e.preventDefault()
    }

    playerElement.onmousedown = e => {
      this.emitMouseDown(e.button, e.offsetX, e.offsetY)
      e.preventDefault()
    }

    playerElement.onmouseup = e => {
      this.emitMouseUp(e.button, e.offsetX, e.offsetY)
      e.preventDefault()
    }

    // When the context menu is shown then it is safest to release the button
    // which was pressed when the event happened. This will guarantee we will
    // get at least one mouse up corresponding to a mouse down event. Otherwise
    // the mouse can get stuck.
    // https://github.com/facebook/react/issues/5531
    playerElement.oncontextmenu = e => {
      this.emitMouseUp(e.button, e.offsetX, e.offsetY)
      e.preventDefault()
    }

    if ('onmousewheel' in playerElement) {
      playerElement.onmousewheel = e => {
        this.emitMouseWheel(e.wheelDelta, e.offsetX, e.offsetY)
        e.preventDefault()
      }
    } else {
      playerElement.addEventListener(
        'DOMMouseScroll',
        e => {
          this.emitMouseWheel(e.detail * -120, e.offsetX, e.offsetY)
          e.preventDefault()
        },
        false,
      )
    }

    playerElement.pressMouseButtons = e => {
      this.pressMouseButtons(e.buttons, e.offsetX, e.offsetY)
    }

    playerElement.releaseMouseButtons = e => {
      this.releaseMouseButtons(e.buttons, e.offsetX, e.offsetY)
    }
  }

  // If the user has any mouse buttons pressed then release them.
  releaseMouseButtons = (buttons, x, y) => {
    if (buttons & MouseButtonsMask.PrimaryButton) {
      this.emitMouseUp(MouseButton.MainButton, x, y)
    }
    if (buttons & MouseButtonsMask.SecondaryButton) {
      this.emitMouseUp(MouseButton.SecondaryButton, x, y)
    }
    if (buttons & MouseButtonsMask.AuxiliaryButton) {
      this.emitMouseUp(MouseButton.AuxiliaryButton, x, y)
    }
    if (buttons & MouseButtonsMask.FourthButton) {
      this.emitMouseUp(MouseButton.FourthButton, x, y)
    }
    if (buttons & MouseButtonsMask.FifthButton) {
      this.emitMouseUp(MouseButton.FifthButton, x, y)
    }
  }

  // If the user has any mouse buttons pressed then press them again.
  pressMouseButtons = (buttons, x, y) => {
    if (buttons & MouseButtonsMask.PrimaryButton) {
      this.emitMouseDown(MouseButton.MainButton, x, y)
    }
    if (buttons & MouseButtonsMask.SecondaryButton) {
      this.emitMouseDown(MouseButton.SecondaryButton, x, y)
    }
    if (buttons & MouseButtonsMask.AuxiliaryButton) {
      this.emitMouseDown(MouseButton.AuxiliaryButton, x, y)
    }
    if (buttons & MouseButtonsMask.FourthButton) {
      this.emitMouseDown(MouseButton.FourthButton, x, y)
    }
    if (buttons & MouseButtonsMask.FifthButton) {
      this.emitMouseDown(MouseButton.FifthButton, x, y)
    }
  }

  registerInputs = () => {
    if (!this.playerRef) return

    this.registerMouseEnterAndLeaveEvents()
    this.registerTouchEvents()
  }

  registerTouchEvents = () => {
    let playerElement = this.playerRef
    // We need to assign a unique identifier to each finger.
    // We do this by mapping each Touch object to the identifier.

    let rememberTouch = touch => {
      const finger = this.fingers.pop()

      if (finger === undefined) {
        console.log('exhausted touch indentifiers')
      }

      console.log('Register', touch.identifier, finger)

      this.fingerIds.set(touch.identifier, finger)
    }

    let forgetTouch = touch => {
      this.fingers.push(this.fingerIds.get(touch.identifier))

      // Sort array back into descending order. This means if finger '1' were to lift after finger '0', we would ensure that 0 will be the first index to pop
      this.fingers.sort(function (a, b) {
        return b - a
      })
      this.fingerIds.delete(touch.identifier)
    }

    // if (inputOptions.fakeMouseWithTouches) {
    if (false) {
      var finger = undefined

      playerElement.ontouchstart = e => {
        if (finger === undefined) {
          let firstTouch = e.changedTouches[0]
          finger = {
            id: firstTouch.identifier,
            x: firstTouch.clientX - this.playerElementClientRect.left,
            y: firstTouch.clientY - this.playerElementClientRect.top,
          }
          // Hack: Mouse events require an enter and leave so we just
          // enter and leave manually with each touch as this event
          // is not fired with a touch device.
          playerElement.onmouseenter(e)
          this.emitMouseDown(MouseButton.MainButton, finger.x, finger.y)
        }
        e.preventDefault()
      }

      playerElement.ontouchend = e => {
        for (let t = 0; t < e.changedTouches.length; t++) {
          let touch = e.changedTouches[t]
          if (touch.identifier === finger.id) {
            let x = touch.clientX - this.playerElementClientRect.left
            let y = touch.clientY - this.playerElementClientRect.top
            this.emitMouseUp(MouseButton.MainButton, x, y)
            // Hack: Manual mouse leave event.
            playerElement.onmouseleave(e)
            finger = undefined
            break
          }
        }
        e.preventDefault()
      }

      playerElement.ontouchmove = e => {
        for (let t = 0; t < e.touches.length; t++) {
          let touch = e.touches[t]
          if (touch.identifier === finger.id) {
            let x = touch.clientX - this.playerElementClientRect.left
            let y = touch.clientY - this.playerElementClientRect.top
            this.emitMouseMove(x, y, x - finger.x, y - finger.y)
            finger.x = x
            finger.y = y
            break
          }
        }
        e.preventDefault()
      }
    } else {
      playerElement.ontouchstart = e => {
        // Assign a unique identifier to each touch.
        for (let t = 0; t < e.changedTouches.length; t++) {
          rememberTouch(e.changedTouches[t])
        }

        if (this.print_inputs) {
          console.log('touch start')
        }
        this.emitTouchData(MessageType.TouchStart, e.changedTouches)
        e.preventDefault()
      }

      playerElement.ontouchend = e => {
        if (this.print_inputs) {
          console.log('touch end')
        }
        this.emitTouchData(MessageType.TouchEnd, e.changedTouches)

        // Re-cycle unique identifiers previously assigned to each touch.
        for (let t = 0; t < e.changedTouches.length; t++) {
          forgetTouch(e.changedTouches[t])
        }
        e.preventDefault()
      }

      playerElement.ontouchmove = e => {
        if (this.print_inputs) {
          console.log('touch move')
        }
        this.emitTouchData(MessageType.TouchMove, e.touches)
        e.preventDefault()
      }
    }
  }

  registerMouseEnterAndLeaveEvents = () => {
    this.playerRef.onmouseenter = e => {
      if (this.print_inputs) {
        console.log('mouse enter')
      }
      let data = new DataView(new ArrayBuffer(1))
      data.setUint8(0, MessageType.MouseEnter)
      this.sendInputData(data.buffer)
      this.playerRef.pressMouseButtons(e)
    }

    this.playerRef.onmouseleave = e => {
      if (this.print_inputs) {
        console.log('mouse leave')
      }
      let data = new DataView(new ArrayBuffer(1))
      data.setUint8(0, MessageType.MouseLeave)
      this.sendInputData(data.buffer)
      this.playerRef.releaseMouseButtons(e)
    }
  }

  sendInputData = data => {
    // if (webRtcPlayerObj) {
    //   resetAfkWarningTimer();
    this.context.send(data)
    // }
  }

  emitMouseMove = (x, y, deltaX, deltaY) => {
    if (this.print_inputs) {
      console.log(`x: ${x}, y:${y}, dX: ${deltaX}, dY: ${deltaY}`)
    }
    let coord = this.normalizeAndQuantizeUnsigned(x, y)
    let delta = this.normalizeAndQuantizeSigned(deltaX, deltaY)
    var Data = new DataView(new ArrayBuffer(9))
    Data.setUint8(0, MessageType.MouseMove)
    Data.setUint16(1, coord.x, true)
    Data.setUint16(3, coord.y, true)
    Data.setInt16(5, delta.x, true)
    Data.setInt16(7, delta.y, true)
    this.sendInputData(Data.buffer)
  }

  emitMouseDown = (button, x, y) => {
    if (this.print_inputs) {
      console.log(`mouse button ${button} down at (${x}, ${y})`)
    }
    let coord = this.normalizeAndQuantizeUnsigned(x, y)
    var Data = new DataView(new ArrayBuffer(6))
    Data.setUint8(0, MessageType.MouseDown)
    Data.setUint8(1, button)
    Data.setUint16(2, coord.x, true)
    Data.setUint16(4, coord.y, true)
    this.sendInputData(Data.buffer)
  }

  emitTouchData = (type, touches) => {
    let playerElement = this.playerRef

    console.log('remembered fingers', this.fingerIds.entries.length)

    for (let t = 0; t < touches.length; t++) {
      let data = new DataView(new ArrayBuffer(9))
      const numTouches = 1 // the number of touches to be sent this message
      const touch = touches[t]

      let x = touch.clientX - this.playerElementClientRect.left
      let y = touch.clientY - this.playerElementClientRect.top
      console.log(touch.clientX, touch.clientY, this.playerElementClientRect.left, this.playerElementClientRect.top)

      let coord = this.normalizeAndQuantizeUnsigned(x, y)
      if (this.print_inputs) {
        console.log(`F${this.fingerIds.get(touch.identifier)}=(${x}, ${y})`)
        console.log(`F${this.fingerIds.get(touch.identifier)}=C(${coord.x}, ${coord.y})`)
      }

      data.setUint8(0, type)
      data.setUint8(1, numTouches)
      let byte = 2
      data.setUint16(byte, coord.x, true)
      byte += 2
      data.setUint16(byte, coord.y, true)
      byte += 2
      data.setUint8(byte, this.fingerIds.get(touch.identifier))
      byte += 1
      data.setUint8(byte, 255 * touch.force) // force is between 0.0 and 1.0 so quantize into byte.
      byte += 1
      data.setUint8(byte, coord.inRange ? 1 : 0)
      this.sendInputData(data.buffer)
    }
  }

  emitMouseUp = (button, x, y) => {
    if (this.print_inputs) {
      console.log(`mouse button ${button} up at (${x}, ${y})`)
    }
    let coord = this.normalizeAndQuantizeUnsigned(x, y)
    var Data = new DataView(new ArrayBuffer(6))
    Data.setUint8(0, MessageType.MouseUp)
    Data.setUint8(1, button)
    Data.setUint16(2, coord.x, true)
    Data.setUint16(4, coord.y, true)
    this.sendInputData(Data.buffer)
  }

  emitMouseWheel = (delta, x, y) => {
    if (this.print_inputs) {
      console.log(`mouse wheel with delta ${delta} at (${x}, ${y})`)
    }
    let coord = this.normalizeAndQuantizeUnsigned(x, y)
    var Data = new DataView(new ArrayBuffer(7))
    Data.setUint8(0, MessageType.MouseWheel)
    Data.setInt16(1, delta, true)
    Data.setUint16(3, coord.x, true)
    Data.setUint16(5, coord.y, true)
    this.sendInputData(Data.buffer)
  }

  setupNormalizeAndQuantize = () => {
    let playerElement = this.playerRef
    let videoElement = this.videoRef

    if (playerElement && videoElement) {
      let playerAspectRatio = playerElement.clientHeight / playerElement.clientWidth
      let videoAspectRatio = 1080 / 1920

      if (playerAspectRatio > videoAspectRatio) {
        let ratio = videoAspectRatio / playerAspectRatio
        // Unsigned.
        this.normalizeAndQuantizeUnsigned = (x, y) => {
          let normalizedX = ratio * (x / playerElement.clientWidth - 0.5) + 0.5
          let normalizedY = y / playerElement.clientHeight
          if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
            return {
              inRange: false,
              x: 65535,
              y: 65535,
            }
          } else {
            return {
              inRange: true,
              x: normalizedX * 65536,
              y: normalizedY * 65536,
            }
          }
        }
        // this.unquantizeAndDenormalizeUnsigned = (x, y) => {
        //   let normalizedX = x / 65536;
        //   let normalizedY = (y / 65536 - 0.5) / ratio + 0.5;
        //   return {
        //     x: normalizedX * playerElement.clientWidth,
        //     y: normalizedY * playerElement.clientHeight,
        //   };
        // };
        // Signed.
        this.normalizeAndQuantizeSigned = (x, y) => {
          let normalizedX = (ratio * x) / (0.5 * playerElement.clientWidth)
          let normalizedY = y / (0.5 * playerElement.clientHeight)
          return {
            x: normalizedX * 32767,
            y: normalizedY * 32767,
          }
        }
      } else {
        let ratio = playerAspectRatio / videoAspectRatio

        // Unsigned.
        this.normalizeAndQuantizeUnsigned = (x, y) => {
          let normalizedX = 1 * (x / playerElement.clientWidth)
          let normalizedY = ratio * (y / playerElement.clientHeight - 0.5) + 0.5

          if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
            return {
              inRange: false,
              x: 65535,
              y: 65535,
            }
          } else {
            return {
              inRange: true,
              x: normalizedX * 65536,
              y: normalizedY * 65536,
            }
          }
        }
        // unquantizeAndDenormalizeUnsigned = (x, y) => {
        //   let normalizedX = (x / 65536 - 0.5) / ratio + 0.5;
        //   let normalizedY = y / 65536;
        //   return {
        //     x: normalizedX * playerElement.clientWidth,
        //     y: normalizedY * playerElement.clientHeight,
        //   };
        // };
        // Signed.
        this.normalizeAndQuantizeSigned = (x, y) => {
          let normalizedX = x / (0.5 * playerElement.clientWidth)
          let normalizedY = (ratio * y) / (0.5 * playerElement.clientHeight)
          return {
            x: normalizedX * 32767,
            y: normalizedY * 32767,
          }
        }
      }
    }
  }

  render() {
    return (
      <div
        className='player'
        ref={element => {
          this.playerRef = element
        }}>
        <video {...this.props} ref={this.setVideoRef} />
      </div>
    )
  }
}

Player.contextType = PixelStreamingContext
export default Player

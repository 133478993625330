import React from 'react'
// Styles
import './Front.css'

import Mobile from './newMobile'
import Preview from '../Preview'
import MediaQuery from 'react-responsive'

import PixelStreamingContext from '../PixelStreaming/Context'

import DefaultData from '../../data'

const ACTIVE_MODEL = DefaultData[process.env.REACT_APP_ACTIVE_MODEL]

class Front extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      model: ACTIVE_MODEL.defaultModel,
      settings: {
        color: ACTIVE_MODEL.defaultColor,
      },
    }
  }

  handleOnSelectedOption = option => {
    let currModel = this.state.model
    let needChange = option.models.find(model => currModel === model)

    if (needChange !== currModel) {
      // find next model
      let next = option.models.find(model => model > currModel)
      let modelsCopy = [...option.models]
      let prev = modelsCopy.reverse().find(model => model < currModel)

      if (next !== undefined) {
        this.changeModel(next, option)
        return
      } else if (prev !== undefined) {
        this.changeModel(prev, option)
        return
      }
    }

    let descriptor = {
      Instruction: option.type,
      Value: option.id,
    }

    this.emitDescriptor(50, descriptor)

    let currSettings = this.state.settings
    currSettings[option.type] = option.id
    this.setState({ settings: currSettings })
  }

  changeModel = (model, option) => {
    let currSettings = this.state.settings
    let posibleSettings = ACTIVE_MODEL.model_settings[model]

    currSettings.color = this.getNextOrPrev(currSettings.color, posibleSettings.color)

    if (option) {
      currSettings[option.type] = option.id
    }

    this.setState({ model: model, settings: currSettings })

    let descriptor = {
      Instruction: 'model',
      Value: model,
      Settings: this.state.settings,
    }
    this.emitDescriptor(50, descriptor)
  }

  getNextOrPrev = (curr, posibles) => {
    let result = posibles.find(p => p === curr)
    if (result === undefined) {
      result = posibles.find(p => p > curr)
    }
    if (result === undefined) {
      let posiblesCopy = [...posibles]
      result = posiblesCopy.reverse().find(p => p < curr)
    }

    return result
  }

  changeCamera = id => {
    let descriptor = {
      Instruction: 'camera',
      Value: id,
    }

    this.emitDescriptor(50, descriptor)
  }

  // A generic message has a type and a descriptor.
  emitDescriptor = (messageType, descriptor) => {
    // if (process.env.NODE_ENV !== "production") {
    //   console.log("Sended descriptor: ", descriptor);
    //   return;
    // }
    // Convert the dscriptor object into a JSON string.
    let descriptorAsString = JSON.stringify(descriptor)
    console.log('Sended descriptor: ', descriptor)

    // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
    // a time.
    let data = new DataView(new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length))
    let byteIdx = 0
    data.setUint8(byteIdx, messageType)
    byteIdx++
    data.setUint16(byteIdx, descriptorAsString.length, true)
    byteIdx += 2
    for (let i = 0; i < descriptorAsString.length; i++) {
      data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true)
      byteIdx += 2
    }
    this.sendInputData(data.buffer)
  }

  zoomIn = () => {
    // if (process.env.NODE_ENV !== "production") {
    //   console.log("Sended zoomIn: ");
    //   return;
    // }
    var Data = new DataView(new ArrayBuffer(7))
    Data.setUint8(0, 75)
    Data.setInt16(1, 120, true)
    Data.setUint16(3, 0, true)
    Data.setUint16(5, 0, true)
    this.sendInputData(Data.buffer)
  }

  zoomOut = () => {
    // if (process.env.NODE_ENV !== "production") {
    //   console.log("Sended zoomOut: ");
    //   return;
    // }
    //console.log("zoomOut");
    var Data = new DataView(new ArrayBuffer(7))
    Data.setUint8(0, 75)
    Data.setInt16(1, -120, true)
    Data.setUint16(3, 0, true)
    Data.setUint16(5, 0, true)
    this.sendInputData(Data.buffer)
  }

  sendInputData = data => {
    this.context.send(data)
  }

  render() {
    return (
      <div className='h-full configurator'>
        <MediaQuery minWidth={1048}>
          <Panels
            currentVariant={this.state.model}
            currentColor={this.state.settings.color}
            handleChangeVariant={this.changeModel}
            handleChangeCamera={this.changeCamera}
            handleChangeColor={this.handleOnSelectedOption}
          />
        </MediaQuery>
        <MediaQuery maxWidth={1048} orientation={'landscape'}>
          <Preview className='object-cover w-screen h-screen -z-40'></Preview>
        </MediaQuery>
        <MediaQuery maxWidth={1048} orientation={'portrait'}>
          <div className='absolute z-0 flex w-screen pl-12 cursor-pointer h-3/4 overflow-clip'>
            <Preview className='object-cover w-screen h-full'></Preview>
          </div>
          <Mobile
            currentVariant={this.state.model}
            currentColor={this.state.settings.color}
            handleChangeVariant={this.changeModel}
            handleChangeCamera={this.changeCamera}
            handleChangeColor={this.handleOnSelectedOption}
            handleZoomIn={this.zoomIn}
            handleZoomOut={this.zoomOut}
          />
        </MediaQuery>
      </div>
    )
  }
}

class Panels extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      exterior: true,
      filteredCameras: [],
      menuActions: null,
    }
  }

  componentDidMount = () => {
    this.handleSetCameras(this.state.exterior, this.setMenuActions)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.currentVariant !== this.props.currentVariant) {
      this.handleSetCameras(this.state.exterior)
    }
  }

  handleSetExterior = ext => {
    this.handleSetCameras(ext)
    this.setState({ exterior: ext })
  }

  handleSetCameras = (isExt, callback) => {
    let possibleCameras = ACTIVE_MODEL.model_settings[this.props.currentVariant].cameras
    let camerasToShow = ACTIVE_MODEL.cameras.filter(camera => camera.exterior === isExt && possibleCameras.some(id => id === camera.id))

    this.setState({ filteredCameras: camerasToShow }, callback)
  }

  setMenuActions = () => {
    let volante = ACTIVE_MODEL.cameras.find(camera => camera.name === 'Volante')
    let exterior = ACTIVE_MODEL.cameras.find(camera => camera.name === 'Exterior')

    this.setState({
      menuActions: [
        {
          name: 'Interior',
          camera: volante,
        },
        {
          name: 'Exterior',
          camera: exterior,
        },
      ],
    })
  }

  render() {
    return (
      <div className='relative h-full'>
        <BottomNavbar
          currentVariant={this.props.currentVariant}
          handleChangeVariant={this.props.handleChangeVariant}
          currentColor={this.props.currentColor}
          handleChangeColor={this.props.handleChangeColor}
          isExterior={this.state.exterior}
          handleSetCameras={this.handleSetCameras}
        />
        <RightNavbar cameras={this.state.filteredCameras} handleChangeCamera={this.props.handleChangeCamera} />
        <LeftNavbar setExterior={this.handleSetExterior} menuActions={this.state.menuActions} handleChangeCamera={this.props.handleChangeCamera} />
        <Preview className='object-cover w-screen h-screen -z-40' />
      </div>
    )
  }
}

class LeftNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.labelButtons = [
      // {id: 0, label: "Cinematica"},
      { id: 1, label: 'Interior', exterior: false },
      { id: 2, label: 'Exterior', exterior: true },
    ]

    this.state = {
      selected: 2,
    }
  }

  handleOnClickExterior = button => {
    this.handleOnChangeSelected(button.id)
    if ('exterior' in button) {
      // TODO: modify for cinematics button
      let action = this.props.menuActions.find(action => action.name === button.label)
      this.props.handleChangeCamera(action.camera.id)
      this.props.setExterior(button.exterior)
    }
  }

  handleOnChangeSelected = id => {
    this.setState({ selected: id })
  }

  render() {
    const VerticalButton = props => {
      const selected = props.selected ? 'border-nissanRed-default border-l-2' : ''
      return (
        <button className={`bg-white w-full h-full font-regular text-gray-500  hover:bg-gray-100 hover:text-gray-300 transition duration-600 ease-in-out ${selected}`}>
          <p className='pb-4 rotate-90'>{props.label}</p>
        </button>
      )
    }

    return (
      <div className='absolute right-0 z-40 grid w-1/12 h-full grid-rows-6 gap-2 bg-white py-14'>
        {this.labelButtons &&
          this.labelButtons.map((button, idx) => {
            return (
              <div key={idx} onClick={() => this.handleOnClickExterior(button)}>
                <VerticalButton key={idx} label={button.label} selected={this.state.selected === button.id} />
              </div>
            )
          })}
      </div>
    )
  }
}

class RightNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cameraOptions: [],
    }
  }

  render() {
    const QuoteButton = () => {
      return (
        <button
          className='flex items-center justify-center h-full transition duration-500 ease-in-out bg-nissanRed-default hover:bg-grey-700 max-w-1/12 hover:bg-red-800 '
          onClick={() => {
            window.open(`//${ACTIVE_MODEL.cotizacionURL}`)
          }}>
          <p className='text-xl text-white rotate-90'>Cotizar</p>
        </button>
      )
    }

    const CameraButton = props => {
      return (
        <button className='w-full py-8 break-words hover:bg-white/10' onClick={() => props.handleChange(props.id)}>
          <p className='text-sm font-semibold text-white'>{props.name}</p>
        </button>
      )
    }

    const CameraImgButton = props => {
      return (
        <button className='flex flex-col items-stretch justify-center w-full my-1 break-words hover:bg-white/20 h-1/6 shrink-0' onClick={() => props.handleChange(props.id)}>
          <p className='mb-1 text-xs text-white bg-black'>{props.name}</p>
          <div
            className='w-full h-full bg-center bg-cover'
            alt={props.name}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/${process.env.REACT_APP_ACTIVE_MODEL}/cameras/${props.id}.webp)`,
            }}
          />
        </button>
      )
    }

    return (
      <div className='absolute left-0 z-40 flex flex-col w-1/12 h-full bg-black/30'>
        <div className='flex-none w-full text-sm font-semibold text-center text-gray-500 break-words bg-white h-1/12'>
          <img src={`${process.env.PUBLIC_URL}/img/escenarios/0.webp`} alt={'nissan'} />
        </div>
        <div className='flex flex-col justify-start h-full gap-3 py-2 overflow-x-hidden overflow-y-scroll scrollbar-hide flex-nowrap'>
          {this.props.cameras &&
            this.props.cameras.map((option, idx) => {
              return (
                <CameraImgButton key={idx} id={option.id} handleChange={this.props.handleChangeCamera} name={option.name} />
                // <button
                //   key={idx}
                //   className="w-full py-8 break-words hover:bg-white/10"
                //   onClick={() => this.props.handleChangeCamera(option.id)}
                // >
                //   <p className="text-sm font-semibold text-white">
                //     {option.name}
                //   </p>
                // </button>
              )
            })}
        </div>
        <div className='bottom-0 left-0 flex-none rotate-180 h-1/6'>
          <QuoteButton />
        </div>
      </div>
    )
  }
}

class BottomNavbar extends React.Component {
  constructor(props) {
    super(props)

    // Default: Versions menu
    this.state = {
      menuOptions: ACTIVE_MODEL.settings.menus,
      submenuOptions: ACTIVE_MODEL.settings.menus[1].subMenus,
      selectedMenuId: 1,
    }
  }

  handleOnClickMenu = id => {
    let submenuOptions = ACTIVE_MODEL.settings.menus[id].subMenus
    if (id === 2) {
      submenuOptions = this.filterColors()
    }

    this.setState({
      selectedMenuId: id,
      submenuOptions: submenuOptions,
    })
  }

  filterColors = () => {
    let possibleColors = ACTIVE_MODEL.model_settings[this.props.currentVariant].color
    let allColors = ACTIVE_MODEL.settings.menus[2].subMenus
    let colorsToShow = allColors.filter(color => possibleColors.some(id => id === color.id))

    return colorsToShow
  }

  handleChangeSettings = item => {
    if (this.state.selectedMenuId === 1) this.props.handleChangeVariant(item)
    else if (this.state.selectedMenuId === 2) this.props.handleChangeColor(item)
  }

  render() {
    return (
      <div className='absolute bottom-0 left-0 w-4/6 h-1/6 z-40 overflow-hidden pl-[8.333333%]'>
        <div className='flex flex-row w-full h-full overflow-hidden '>
          <SubMenu
            submenuOptions={this.state.submenuOptions}
            selectedMenuId={this.state.selectedMenuId}
            currentVariant={this.props.currentVariant}
            currentColor={this.props.currentColor}
            handleChangeSettings={this.handleChangeSettings}
          />
          <WheelMenu menuOptions={this.state.menuOptions} selectedMenuId={this.state.selectedMenuId} handleOnClickMenu={this.handleOnClickMenu} />
        </div>
      </div>
    )
  }
}

class WheelMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className='grid grid-rows-3 py-3 gap-1.5 shadow-sm bg-gray-200 shadow-black/40 w-40'>
        {this.props.menuOptions &&
          this.props.menuOptions.map((option, idx) => (
            <button
              key={idx}
              onClick={() => this.props.handleOnClickMenu(option.id)}
              className={`text-base text-center uppercase hover:bg-gray-300 transition duration-500 ease-in-out w-40
              ${idx === this.props.selectedMenuId ? 'font-semibold text-black' : 'text-gray-500'}`}>
              {option.name}
            </button>
          ))}
      </div>
    )
  }
}

class SubMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleChange = submenu => {
    switch (this.props.selectedMenuId) {
      case 0:
        break
      case 1:
        this.props.handleChangeSettings(submenu.id)
        break
      case 2:
        this.props.handleChangeSettings(submenu)
        break
    }
  }

  render() {
    const menuId = this.props.selectedMenuId
    const menuPath = ACTIVE_MODEL.settings.menus[menuId].path
    const menuType = ACTIVE_MODEL.settings.menus[menuId].type

    const submenuBackgrounds = ['bg-white text-gray-700 justify-center', 'bg-nissanGray-default justify-start', 'bg-gray-300 text-gray-700']
    const buttonHoverBackgrounds = ['', 'hover:bg-gray-300/20', 'hover:bg-gray-700/20']

    const buttonSelected = ['bg-black-300/90', 'text-gray-500 hover:text-gray-700', 'bg-black/20']

    // TODO: set button selected when this.props.currentColor and this.props.currentVariant
    const SubMenuButton = props => {
      let background

      if (menuType === 'label') {
        background = props.currentVariant == props.submenu.id ? props.selected : props.background

        return (
          <button
            className={`items-center basis-1/5 transition duration-500 ease-in-out shrink-0 ${background}`}
            onClick={() => {
              this.handleChange(props.submenu)
            }}>
            <p className={`font-semibold  `}>{props.submenu.name}</p>
          </button>
        )
      } else if (menuType === 'image') {
        let selected = props.currentColor == props.submenu.id ? true : false
        let effects = selected ? 'border border-black' : ' '

        return (
          <button
            className={`w-fit mx-4 overflow-clip transition duration-500 ease-in-out shrink-0`}
            onClick={() => {
              this.handleChange(props.submenu)
            }}>
            <div className={`flex h-full overflow-clip relative items-center justify-center`}>
              <img
                className={`h-1/2 rounded-full ${effects}`}
                src={`${process.env.PUBLIC_URL}/img/${process.env.REACT_APP_ACTIVE_MODEL}/${menuPath}/${props.submenu.id}.webp`}
                alt={props.submenu.name}
              />
            </div>
          </button>
        )
      }
    }

    return (
      <div className={`flex flex-nowrap justify-start text-white w-full overflow-x-auto transition duration-600 ease-in ${submenuBackgrounds[menuId]}`}>
        {this.props.submenuOptions &&
          this.props.submenuOptions.map((submenu, idx) => {
            return (
              <SubMenuButton
                key={idx}
                background={buttonHoverBackgrounds[menuId]}
                submenu={submenu}
                currentColor={this.props.currentColor}
                currentVariant={this.props.currentVariant}
                selected={buttonSelected[menuId]}
              />
            )
          })}
      </div>
    )
  }
}

Front.contextType = PixelStreamingContext
export default Front

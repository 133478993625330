import React from "react";
// Styles
import "./Welcome.css";

import Logo from "../Logo";
// import Button from '@material-ui/core/Button';

import BrighttiServiceContext from "../BrighttiService/Context";

const isMantaining = false;

class WelcomeScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // console.log("WelcomeScreen componentDidMount");
  }

  render() {
    return (
      <div className="welcome-screen max-w-screen min-h-screen-ios h-screen-ios h-full">
        <div className="brand-logo">
          <Logo inverted />
        </div>
        <>
          <div className="message-text">
            <p>Bienvenido al Showroom Virtual de Nissan</p>
            <p>
              Para la mejor experiencia, te recomendamos que navegues esta
              sección con una conexión WIFI o una conexión a internet óptimo
            </p>
          </div>
          <div className="call-actions">
            <button
              className="border border-white px-2 py-2 rounded hover:bg-black/10 transition duration-500 ease-in-out"
              onClick={this.props.handleStartVideo}
            >
              Comenzar Ahora
            </button>
          </div>
        </>
        {isMantaining && (
          <>
            <div className="message-text">
              <p>Bienvenido al Showroom Virtual de Nissan</p>
              <p>
                Estamos realizando una actualización para mejorar la
                experiencia, inténtelo más tarde
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
}

class QueueScreen extends React.Component {
  constructor(props) {
    super(props);
    this.isConnected = this.props.isConnected;
    this.handleOnConnected = this.props.onConnected;
  }

  componentDidMount() {
    // console.log("QueueScreen componentDidMount");
  }

  componentDidUpdate(prevProps) {
    if (this.props.isConnected !== prevProps.isConnected) {
      this.handleOnConnected();
    }
  }

  render() {
    let spinnerImg = `${process.env.PUBLIC_URL}/img/loading.svg`;
    return (
      <div className="queue-screen h-full">
        <img className="loading-spinner" src={spinnerImg} />
      </div>
    );
  }
}

const ReactLazyPreload = (importStatement) => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const VideoPlayer = ReactLazyPreload(() => import("../VideoPlayer"));

class StartScreenLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ready: false,
    };

    this.handleOnEndVideo = this.props.onEnded;
  }

  componentDidMount = () => {
    VideoPlayer.preload();
  };

  startVideo = () => {
    this.setState(() => ({
      playing: true,
    }));
    this.props.start();
  };

  handleOnReadyPlayer = () => {
    this.setState(() => ({
      loadding: true,
      ready: true,
    }));
  };

  render() {
    const isLoadding = this.state.loadding;
    const isPlaying = this.state.playing;
    const canSkip = this.props.canSkip;

    const spinnerImg = `${process.env.PUBLIC_URL}/img/loading.svg`;

    return (
      <>
        {
          <React.Suspense fallback={<QueueScreen />}>
            <div className="flex flex-col justify-center h-screen-ios h-full">
              <VideoPlayer
                className="bg-black"
                width="100%"
                height="100%"
                playsinline
                playing={isPlaying}
                volume={0.6}
                onReady={this.handleOnReadyPlayer}
                onEnded={this.handleOnEndVideo}
                url={process.env.REACT_APP_PRESENTATION_VIDEO_URL}
              />
            </div>
            <div className="connection-status">
              {!canSkip && <img className="loading-spinner" src={spinnerImg} />}
              {canSkip && (
                <button
                  className="border text-white border-white px-8 py-2 rounded hover:bg-black/30 transition duration-500 ease-in-out"
                  onClick={this.props.showStream}
                >
                  Saltar
                </button>
              )}
            </div>
          </React.Suspense>
        }

        {!isPlaying && <WelcomeScreen handleStartVideo={this.startVideo} />}
      </>
    );
  }
}

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeEnd: false,
    };
  }

  handleOnEndedWelcome = () => {
    if (this.context.connected) {
      this.context.setShowStream();
    } else {
      this.setState(() => ({
        welcomeEnd: true,
      }));
    }
  };

  componentDidMount() {
    // console.log("Welcome componentDidMount");
  }

  render() {
    return (
      <BrighttiServiceContext.Consumer>
        {(brighttiServiceContext) => {
          return (
            <>
              {!this.state.welcomeEnd && (
                <StartScreenLoader
                  onEnded={this.handleOnEndedWelcome}
                  start={brighttiServiceContext.startConn}
                  canSkip={brighttiServiceContext.connected}
                  showStream={brighttiServiceContext.setShowStream}
                />
              )}
              {this.state.welcomeEnd && (
                <QueueScreen
                  isConnected={brighttiServiceContext.connected}
                  onConnected={brighttiServiceContext.setShowStream}
                />
              )}
            </>
          );
        }}
      </BrighttiServiceContext.Consumer>
    );
  }
}

Welcome.contextType = BrighttiServiceContext;

export default Welcome;

import React from "react";
// Styles
// import './Preview.css';
// Components
import Player from "../PixelStreaming/Player";

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    console.log("Preview componentDidMount", this.videoRef);
    this.videoRef.play();
  }

  render() {
    return (
      <Player
        autoPlay
        muted
        playsInline
        className={this.props.className}
        ref={(component) => {
          console.log("added component player", component);
          this.videoRef = component;
        }}
      />
    );
  }
}

export default Preview;

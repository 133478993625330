import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import Welcome from "./containers/Welcome";

import PixelStreaming from "./containers/PixelStreaming/Provider";
import BrighttiService from "./containers/BrighttiService/Provider";
import BrighttiServiceContext from "./containers/BrighttiService/Context";

// import BrighttiApp from './containers/BrighttiApp';
// import App from './containers/App';
import Front from "./containers/Front";
import reportWebVitals from "./reportWebVitals";

import NewFront from "./containers/Front/newFront";

import DefaultData from "./data";

const ReactLazyPreload = (importStatement) => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const ACTIVE_MODEL = DefaultData[process.env.REACT_APP_ACTIVE_MODEL];

console.log(ACTIVE_MODEL);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
    };
  }
  componentDidMount() {
    document.title = `Nissan Showroom | ${ACTIVE_MODEL.name}`;
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  render() {
    return (
      <div style={{ height: this.state.height }}>{this.props.children}</div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App>
      <BrighttiService>
        <BrighttiServiceContext.Consumer>
          {(brighttiServiceContext) => {
            const protocol =
              process.env.NODE_ENV === "production" ? "wss://" : "ws://";
            const devSignallingPort =
              process.env.NODE_ENV === "production"
                ? ""
                : process.env.REACT_APP_DEV_SIGNALLING_PORT;
            return (
              <>
                {!brighttiServiceContext.showStream && <Welcome />}
                {brighttiServiceContext.reservationID && (
                  <PixelStreaming
                    config={{
                      url: `${protocol}${brighttiServiceContext.signallingIP}:${devSignallingPort}/`,
                      streamerKey: brighttiServiceContext.reservationID,
                      uuid: brighttiServiceContext.qid,
                      reservationKey: brighttiServiceContext.reservationKey,
                    }}
                    onConnected={brighttiServiceContext.handleOnConnected}
                  >
                    {brighttiServiceContext.showStream && <NewFront />}
                  </PixelStreaming>
                )}
              </>
            );
          }}
        </BrighttiServiceContext.Consumer>
      </BrighttiService>
      {/* <NewFront /> */}
    </App>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

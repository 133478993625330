import React from "react";
import axios from "axios";
import BrighttiServiceContext from "./Context";

import DefaultData from "../../data";

const ACTIVE_MODEL = DefaultData[process.env.REACT_APP_ACTIVE_MODEL];

class BrighttiService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      queued: false,
      connectionStep: 0,
      qid: "",
      reservationKey: null,
      reservationID: null,
      showStream: false,
      startConn: this.startConnection,
      setShowStream: () => this.setState({ showStream: true }),
      handleOnConnected: () => this.setState({ connected: true }),
    };

    this.reservationUrl = process.env.REACT_APP_RESERVATION_URL;
    this.timer = null;
    this.ws = null;
  }

  componentDidMount() {
    //console.log('BrighttiService componentDidMount');
  }

  connectToReservation = () => {
    if (!window.WebSocket && !window.MozWebSocket)
      throw new Error("Your browser doesn't support WebSocket");

    const ws = window.WebSocket
      ? new window.WebSocket(
          this.reservationUrl +
            `?reservationKey=${this.state.reservationKey}&uuid=${this.state.qid}&p=${ACTIVE_MODEL.id}`
        )
      : new window.MozWebSocket(
          this.reservationUrl +
            `?reservationKey=${this.state.reservationKey}&uuid=${this.state.qid}&p=${ACTIVE_MODEL.id}`
        );

    ws.onopen = this.onReservationOpen;
    ws.onmessage = this.onReservationMessage;
    ws.onerror = this.onReservationError;
    ws.onclose = this.onReservationClose;

    return ws;
  };

  onReservationMessage = (e) => {
    //console.log(`<- RWS: ${e.data}`);
    var msg = JSON.parse(e.data);
    if (msg.type && msg.type == "reservation") {
      this.setState({
        reservationID: msg.reservationID,
        signallingIP: msg.ip,
        signallingPort: msg.port,
      });
      this.ws.close();
    }
  };

  onReservationOpen = () => {
    console.log("Websocket connected to Reservation");
  };

  onReservationError = (e) => {
    console.log(`WS error: ${JSON.stringify(e)}`);
  };

  onReservationClose = (e) => {
    console.log(`WS closed: ${JSON.stringify(e.code)} - ${e.reason}`);
    this.ws = null;
    // is_reconnection = true;
    // destroy `webRtcPlayerObj` if any
    // let playerDiv = document.getElementById('player');
    // if (webRtcPlayerObj) {
    //     playerDiv.removeChild(webRtcPlayerObj.video);
    //     webRtcPlayerObj.close();
    //     webRtcPlayerObj = undefined;

    // start();
  };

  startConnection = () => {
    console.log("StartConnection");
    let delay = 1000;
    const baseUrl = process.env.REACT_APP_SESSIONMANAGER_URL + "/start";
    const axiosConfig = {
      // withCredentials: true,
      headers: {
        // Accept: 'application/json',
        "Content-Type": "application/json",
      },
    };

    this.timer = setInterval(() => {
      axios.get(baseUrl, axiosConfig).then((result) => {
        if (result.status !== 200) {
          return;
        }

        if (result.data.qid) {
          this.setState({ qid: result.data.qid, queued: true });
          clearInterval(this.timer);
          this.startQueue();
        }
      });
    }, delay);
  };

  startQueue = () => {
    let delay = 1000;
    const baseUrl = process.env.REACT_APP_SESSIONMANAGER_URL + "/status";
    const axiosConfig = {
      // withCredentials: true,
      headers: {
        // Accept: 'application/json',
        "Content-Type": "application/json",
      },
    };

    this.timer = setInterval(() => {
      console.log("QueueConnection");
      axios
        .get(baseUrl, {
          params: {
            qid: this.state.qid,
          },
          ...axiosConfig,
        })
        .then((result) => {
          if (result.status !== 200) {
            return;
          }

          if (result.data) {
            console.log("Queue result", result);
            console.log("QID", this.state.qid);
            let data = result.data;
            if (data.state && data.state == "not_found") {
              clearInterval(this.timer);
              this.startConnection();
            }

            if (data.state && data.state == "dequeued") {
              this.setState({
                queued: false,
                reservationKey: data.reservationKey,
              });
              clearInterval(this.timer);
              if (this.state.reservationKey) {
                this.startReservation();
                return;
              }
              this.startConnection();
            }
            // clearInterval(this.timer);
          }
        });
    }, delay);
  };

  startReservation = () => {
    this.ws = this.connectToReservation();
  };

  prepareConnectionToStreaming = () => {};

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <BrighttiServiceContext.Provider value={this.state}>
        {this.props.children}
      </BrighttiServiceContext.Provider>
    );
  }
}

export default BrighttiService;
